<template>
    <div @click="handleClick()" class="side-menu-item">
        <img :src="getImage" />
        <p class="small-text side-menu-item-text">{{ parsedName }}</p>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "SideMenuItem",

    components: {},

    props: {
        text: String,
        selected: false,
        name: {
            type: String,
            default: "stats",
        },
    },

    data() {
        return {};
    },

    methods: {
        ...mapActions("site/settings", ["playSound"]),

        handleClick() {
            this.$emit("clicked");
            this.playSound({
                track: "click.wav",
                volume: 0.1,
            });
        },
    },

    computed: {
        parsedName() {
            const name = this.$t(`menu.${this.name}`);
            return name.charAt(0).toUpperCase() + name.slice(1);
        },

        getImage() {
            const button = require(`@/assets/buttons/${this.name}Button.png`);
            return button;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.side-menu-item {
    width: 6rem;
    height: 6rem;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
    }

    &:not(.disabled) {
        &:hover:not(.selected) {
            filter: brightness(1.2);
            transform: scale(1.05);
        }

        &:not(.selected) {
        }

        &.selected {
        }
    }

    &-text {
        position: absolute;
        bottom: 0.5rem;

        @media (max-width: 768px) {
            bottom: 0.6rem;
        }
    }

    &.disabled {
        filter: brightness(0.5);
        cursor: default;
    }
}
</style>
