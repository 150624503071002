<template>
    <div @click="handleClick" class="right-menu-item">
        <img :src="getImage" />
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "RightMenuItem",

    components: {},

    props: {
        text: String,
        selected: false,
        name: {
            type: String,
            default: "stats",
        },
    },

    data() {
        return {};
    },

    methods: {
        ...mapActions("site/settings", ["playSound"]),

        handleClick() {
            this.playSound({
                track: "click.wav",
                volume: 0.1,
            });
            this.$emit("clicked");
        },
    },

    computed: {
        parsedName() {
            const name = this.$t(`menu.${this.name}`);
            return name.charAt(0).toUpperCase() + name.slice(1);
        },

        getImage() {
            const button = require(`@/assets/icons/${this.name}.png`);
            return button;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.right-menu-item {
    width: 2rem;
    height: 2rem;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    border-image: url("../../../public/images/buttonBg.png") 6 fill repeat;
    border-image-width: 1rem;
    border-image-outset: 0.5rem;

    img {
        width: 2rem;
        height: 2rem;
        margin-bottom: 0.125rem;
    }

    &:not(.disabled) {
        &:hover:not(.selected) {
            filter: brightness(1.2);
        }

        &:not(.selected) {
        }

        &.selected {
        }
    }

    &-text {
        position: absolute;
        bottom: 0.5rem;
    }

    &.disabled {
        filter: brightness(0.5);
        cursor: default;
    }
}
</style>
