<template>
    <div class="header">
        <p class="header-text">
            {{ text }}
        </p>
    </div>
</template>

<script>
export default {
    name: "Header",

    components: {},

    props: {
        text: {
            type: String,
            default: "",
        },
    },

    data() {
        return {};
    },

    methods: {},

    computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
    width: 100%;
    height: 100%;
    min-height: 2rem;

    border-image: url("../../../public/images/buttonBg.png") 6 fill repeat;
    border-image-width: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.1s ease-in;
    cursor: pointer;

    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
    user-select: none;

    padding: 0 1rem;
}
::v-deep.header-text {
    font-size: 2rem;
    margin-bottom: 0.25rem;

    @media screen and (max-width: 512px) {
        font-size: 1.5rem;
        margin-bottom: 0.25rem;
    }
}
</style>
