/**
 * This module extends the vue-i18n package used for translations
 * throughout this project
 */
import i18n, { fallbackLocale } from "../lang/i18n";
import { default as languageTemplate } from "../lang/template";

/**
 * Lists translation keys based on a common prefix
 *
 * @example
 * // en.js
 * {
 * 	message1: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
 * 	message2: "Omnis quo tempore maxime dignissimos veritatis ab aperiam, quis fuga voluptatibus id at accusantium nisi facilis error!",
 * 	message3: "Modi ut facilis consequatur omnis?",
 * };
 *
 * console.log(listTranslationKeys("message")); // => [ "message1", "message2", "message3" ];
 */
export function* listTranslationKeys(prefix) {
    let i = 0;

    while (i18n.te(`${prefix}${++i}`)) {
        yield `${prefix}${i}`;
    }
}

export function ti(prefix) {
    return Array.from(listTranslationKeys(prefix));
}

/*** lazy loading languages ***/
// https://kazupon.github.io/vue-i18n/guide/lazy-loading.html
const loadedLocales = [fallbackLocale];

export async function ensureLocaleLoaded(lang) {
    return new Promise((resolve) => {
        // If the same language
        if (i18n.locale === lang) {
            return resolve();
        }

        // If the language was already loaded
        if (loadedLocales.includes(lang)) {
            return resolve();
        }

        // If the language hasn't been loaded yet
        return languageTemplate(lang).then((messages) => {
            i18n.setLocaleMessage(lang, messages);
            loadedLocales.push(lang);

            return resolve();
        });
    });
}
