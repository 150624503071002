const state = () => ({
    showTooltip: false,

    tooltipMode: "seed",
    tooltipData: {},

    showTooltipTimer: null,
    tooltipEvent: null,

    tooltipTimeout: null,
});

const mutations = {
    setTooltipMode(state, mode) {
        state.tooltipMode = mode;
    },

    initializeTooltip(state, tooltipData) {
        const { mode, data } = tooltipData;

        state.tooltipMode = mode;
        state.tooltipData = data;
    },

    setTooltipVisiable(state, showTooltip) {
        state.showTooltip = showTooltip;
    },

    setTooltipEvent(state, event) {
        state.tooltipEvent = event;
    },

    setTooltipTimeout(state, timeout) {
        state.tooltipTimeout = timeout;
    },
};

const actions = {
    boot({ dispatch, commit }) {
        document.addEventListener("mousedown", (event) => {
            dispatch("hideTooltip", {});
        });
    },

    async showTooltip({ commit, dispatch, state, rootState }, payload) {
        commit("initializeTooltip", payload);
        commit("setTooltipVisiable", true);
    },

    async hideTooltip({ commit, dispatch, state, rootState }) {
        commit("setTooltipVisiable", false);
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};
