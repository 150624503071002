<template>
    <div @click="handleClick()" class="side-menu-item">
        <img width="" :src="require(`../../assets/icons/${icon}.png`)" />
        <p class="small-text side-menu-item-text">{{ text }}</p>
    </div>
</template>

<script>
export default {
    name: "SidebarBalance",

    components: {},

    props: {
        text: String,
        value: String,
        icon: {
            type: String,
            default: "token",
        },
    },

    data() {
        return {};
    },

    methods: {},

    computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.side-menu-item {
    width: 6rem;
    height: 2rem;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: help;
    gap: 0.125rem;

    background-size: 100% 100%;

    border-image: url("../../../public/images/buttonBg.png") 6 fill repeat;
    border-image-width: 1rem;
    border-image-outset: 0.5rem;

    &-text {
        font-size: 1.4rem !important;

        margin-bottom: 0.1rem;
    }

    img {
        transform: scale(0.85);
        margin-bottom: 0.1rem;
    }
}
</style>
