import Vue from "vue";

const state = {
    planterState: "inactive", // inactive, planting, harvesting
    planterSpots: new Array(30).fill(""),
    selectedPlantSpots: [],
    planterBalances: {},

    selectedPlantIndex: 0,
    selectedSeed: "",

    hoverPlantIndex: -1,
    hoverPlant: {},
    hoverPosition: { x: 0, y: 0 },
    showTooltipTimer: null,
    showTooltip: false,

    selectedConsumable: null,
};

const mutations = {
    setPlanterState(state, planterState) {
        state.planterState = planterState;
    },

    setPlanterSpots(state, spots) {
        state.planterSpots = spots;
    },

    setSelectedPlantIndex(state, index) {
        state.selectedPlantIndex = index;
    },

    markPlantIndex(state, data) {
        const { index, seed } = data;
        Vue.set(state.planterSpots, index, seed);
    },

    setSelectedSeed(state, seed) {
        state.selectedSeed = seed;
    },

    setPlotAtIndex(state, { index, seed }) {
        if (state.planterSpots[index]) {
            state.planterBalances[state.planterSpots[index]]--;
        }
        Vue.set(state.planterSpots, index, seed);
    },

    resetBalances(state) {
        state.planterBalances = {};
    },

    addPlanterBalance(state, { seed, balance }) {
        if (!state.planterBalances[seed]) {
            state.planterBalances[seed] = 1;
            return;
        }
        state.planterBalances[seed]++;
    },

    substractPlanterBalance(state, { seed, balance }) {
        if (!state.planterBalances[seed]) {
            state.planterBalances[seed] = 0;
            return;
        }

        state.planterBalances[seed]--;
    },

    setPlantHovered(state, crop) {
        const index = crop ? crop?.index : -1;

        clearTimeout(state.showTooltipTimer);
        if (index === -1) {
            state.showTooltip = false;
            return;
        }

        state.showTooltipTimer = setTimeout(() => {
            state.showTooltip = true;
        }, 500);
        state.hoverPlant = crop;
        state.hoverPlantIndex = index;
    },

    setSelectedConsumable(state, consumable) {
        state.selectedConsumable = consumable;
    },
};

const actions = {
    resetPlanter({ commit, dispatch }) {
        dispatch("panzoom/unlockPanzoom", null, { root: true });
        commit("setPlanterState", "inactive");
        commit("setPlanterSpots", new Array(30).fill(""));
        commit("setSelectedPlantIndex", 0);
        commit("setSelectedSeed", "");
        commit("resetBalances");
    },

    setPlanterState({ commit, dispatch }, planterState) {
        commit("setPlanterState", planterState);
        commit("site/setCurrentModal", null, { root: true });
    },

    setCurrentConsumable({ commit, dispatch }, consumable) {
        commit("setSelectedConsumable", consumable);
    },

    setSelectedPlantIndex({ commit, dispatch }, payload) {
        const { index } = payload;
        commit("setSelectedPlantIndex", index);
    },

    setSelectedSeed({ commit, dispatch }, payload) {
        const { seed } = payload;
        commit("setSelectedSeed", seed);
    },

    markPlantIndex({ commit, dispatch }, payload) {
        commit("markPlantIndex", payload);
    },

    plant({ commit, rootState, state }) {
        const seed = state.selectedSeed;
        const index = state.selectedPlantIndex;

        commit("setPlotAtIndex", { index, seed });
        commit("addPlanterBalance", { seed });
        commit("setSelectedSeed", seed);
    },

    removePlant({ commit, rootState, state }, payload) {
        const index = state.selectedPlantIndex;
        const seed = state.planterSpots[index];
        commit("setPlotAtIndex", { index, plant: null });
        commit("substractPlanterBalance", { seed });
    },

    removePlantAtIndex({ commit, rootState, state }, payload) {
        const { index } = payload;
        const seed = state.planterSpots[index];
        commit("setPlotAtIndex", { index, plant: null });
        commit("substractPlanterBalance", { seed });
    },

    setPlantHovered({ commit, dispatch }, crop) {
        commit("setPlantHovered", crop);
    },

    resetPlantHovered({ commit, dispatch }) {
        commit("setPlantHovered", null);
    },
};

const getters = {};

const planterModule = {
    state: () => state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

export default planterModule;
