<template>
    <div class="secondary-box" :class="[align]">
        <slot name="content"></slot>
    </div>
</template>

<script>
export default {
    name: "SecondaryBox",

    components: {},

    props: {
        icon: String,
        selected: false,
        align: {
            type: String,
            default: "",
        },
    },

    data() {
        return {};
    },

    methods: {
        handleClick() {
            window.dispatchEvent(new Event("playMenuSound"));
            this.$emit("clicked");
        },
    },

    computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.secondary-box {
    min-height: 4rem;
    height: auto;

    border-image: url("../../../public/images/itemBg.png") 6 fill repeat;
    border-image-width: 0.5rem;

    &.centered {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
